import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  FeatureCreditManagement,
  FeatureDataAnalysis,
  FeatureReports
} from '@wallet-manager/pfh-node-def-types/dist/src/FeatureCode';

import { useAppDispatch, useAppSelector } from '../reducer/hooks';
import { selectPermissions } from '../reducer/profileSlice';
import { setView } from '../reducer/stuffSlice';

export const FeatureCodes = {
  creditManagement: {
    customerAccount: FeatureCreditManagement.CustomerAccount.prefix,
    merchantWallet: FeatureCreditManagement.MerchantWallet.prefix,
    customerWallet: FeatureCreditManagement.CustomerWallet.prefix,
    feeWallet: FeatureCreditManagement.FeeWallet.prefix,
    merchantDepositRequest:
      FeatureCreditManagement.MerchantDepositRequest.prefix,
    creditAdjustmentRequest:
      FeatureCreditManagement.CreditAdjustmentRequest.prefix,
    balanceAdjustmentRequest:
      FeatureCreditManagement.BalanceAdjustmentRequest.prefix,
    repaymentSchedule: FeatureCreditManagement.RepaymentSchedule.prefix,
    walletTransaction: FeatureCreditManagement.WalletTransaction.prefix,
    cardTransaction: FeatureCreditManagement.CardTransaction.prefix,
    preCreateCardRequest: FeatureCreditManagement.PreCreateCardRequest.prefix,
    cardList: FeatureCreditManagement.CardList.prefix,
    cardOrderRequest: FeatureCreditManagement.CardOrderRequest.prefix,
  },
  reports: {
    dailyTransaction: FeatureReports.DailyTransaction.prefix,
    customerSpendingReport: FeatureReports.CustomerSpendingReport.prefix,
    transactionPostMonitoringReport:
      FeatureReports.TransactionPostMonitoringReport.prefix,
  },
  dataAnalysis: {
    byProgramConsumption: FeatureDataAnalysis.ByProgramConsumption.prefix,
    byCountryConsumption: FeatureDataAnalysis.ByCountryConsumption.prefix,
    byPfhNoConsumption: FeatureDataAnalysis.ByPfhNoConsumption.prefix,
    byMccConsumption: FeatureDataAnalysis.ByMccConsumption.prefix,
    byPaymentMethodConsumption:
      FeatureDataAnalysis.ByPaymentMethodConsumption.prefix,
    byProgramWithdraw: FeatureDataAnalysis.ByProgramWithdraw.prefix,
    byPfhNoWithdraw: FeatureDataAnalysis.ByPfhNoWithdraw.prefix,
    byCountryWithdraw: FeatureDataAnalysis.ByCountryWithdraw.prefix,
  },
};

//###add-new-page
const { creditManagement, reports, dataAnalysis } = FeatureCodes;
export const routeList: { [key: string]: string[] } = [
  ["homepage", "", ""],
  ["accountSetting", "accountSetting", ""],

  ["customerAccount", "customerAccount", creditManagement.customerAccount],
  ["merchantWallet", "merchantWallet", creditManagement.merchantWallet],
  ["customerWallet", "customerWallet", creditManagement.customerWallet],
  ["feeWallet", "feeWallet", creditManagement.feeWallet],
  [
    "merchantDepositRequest",
    "merchantDepositRequest",
    creditManagement.merchantDepositRequest,
  ],
  [
    "creditAdjustmentRequest",
    "creditAdjustmentRequest",
    creditManagement.creditAdjustmentRequest,
  ],
  [
    "balanceAdjustmentRequest",
    "balanceAdjustmentRequest",
    creditManagement.balanceAdjustmentRequest,
  ],
  [
    "repaymentSchedule",
    "repaymentSchedule",
    creditManagement.repaymentSchedule,
  ],
  [
    "walletTransaction",
    "walletTransaction",
    creditManagement.walletTransaction,
  ],
  ["cardTransaction", "cardTransaction", creditManagement.cardTransaction],
  ["cardList", "cardList", creditManagement.cardList],
  [
    "preCreateCardRequest",
    "preCreateCardRequest",
    creditManagement.preCreateCardRequest,
  ],
  ["cardOrderRequest", "cardOrderRequest", creditManagement.cardOrderRequest],
  ["dailyTransaction", "dailyTransaction", reports.dailyTransaction],
  [
    "customerSpendingReport",
    "customerSpendingReport",
    reports.customerSpendingReport,
  ],
  [
    "transactionPostMonitoringReport",
    "transactionPostMonitoringReport",
    reports.transactionPostMonitoringReport,
  ],
  [
    "byProgramConsumption",
    "byProgramConsumption",
    dataAnalysis.byProgramConsumption,
  ],
  [
    "byCountryConsumption",
    "byCountryConsumption",
    dataAnalysis.byCountryConsumption,
  ],
  ["byPfhNoConsumption", "byPfhNoConsumption", dataAnalysis.byPfhNoConsumption],
  ["byMccConsumption", "byMccConsumption", dataAnalysis.byMccConsumption],
  [
    "byPaymentMethodConsumption",
    "byPaymentMethodConsumption",
    dataAnalysis.byPaymentMethodConsumption,
  ],
  ["byProgramWithdraw", "byProgramWithdraw", dataAnalysis.byProgramWithdraw],
  ["byPfhNoWithdraw", "byPfhNoWithdraw", dataAnalysis.byPfhNoWithdraw],
  ["byCountryWithdraw", "byCountryWithdraw", dataAnalysis.byCountryWithdraw],
].reduce((acc, [key, snakeCase, premission]) => {
  return { ...acc, [key]: [snakeCase, key, premission] };
}, {});
export default function useRoutePermission() {
  const pathname = useLocation().pathname.replace("/", "");
  const dispatch = useAppDispatch();
  const permissionList = useAppSelector(selectPermissions);
  const hasPermission = (key: string) =>
    permissionList.includes(String(key).toLowerCase());
  const viewPermission = getViewPermission(pathname);
  useEffect(() => {
    dispatch(setView(viewPermission));
  }, [viewPermission]);
  const hasRoutePermission = (key: string) => {
    if (key === "homepage" || key === "accountSetting") return true;
    const [_, __, permission] = routeList[key] || [];
    return hasPermission(permission);
  };
  return { hasRoutePermission, viewPermission };
}
export function getViewPermission(pathname: string) {
  const [permission] = Object.values(routeList).find(
    ([_, path]) => path === pathname
  ) || ["homepage"];
  return permission;
}
