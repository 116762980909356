import { FeatureReports } from '@wallet-manager/pfh-node-def-types/dist/src/FeatureCode';

import api from '../../../api';
import { Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = "transactionPostMonitoringReport";

const PermissionKey = FeatureReports.TransactionPostMonitoringReport;

interface Ifield {}

const initFields: Ifield = {};

interface IsearchParam {
  settlementDateFrom: string;
  settlementDateTo: string;
}

const useZusParams = createZusInstance<Ipage & Partial<IsearchParam>>(
  initZusParams
);

const apiObj: {
  export: (params: any, config?: any) => any;
  table: (params: any, config?: any) => any;
} = Object.freeze({
  export: api.Report.transactionPostMonitoringReport.getExport,
  table: api.Report.transactionPostMonitoringReport.getAll,
});

type IgetAllRes = {
  id: number;
  taskId: number;
  merchantId: null | number;
  retrievalReferenceNumber: null | number;
  transactionId: number;
  accountId: number;
  originalTransactionId: null | number;
  relatedTransactionId: null;
  programName: string;
  notificationId: null;
  txCompleted: boolean;
  isAtm: null;
  isAuthorization: boolean;
  isInCountry: null;
  isOnline: null;
  isPos: null;
  creationTime: string;
  settlementDate: string;
  currency: string;
  amount: string;
  transactionSource: number;
  transactionType: number;
  reversal: boolean;
  feeAdjustment: string;
  hidden: boolean;
  isFee: boolean;
  feeAmount: string;
  feeCurrency: null | string;
  taxAmount: string;
  taxCurrency: null;
  externalReference: null | string;
  exchangedAmount: null;
  exchangedCurrency: null;
  exchangeRate: null;
  memo: string;
  customerNumber: string;
  parentCustomerNumber: null;
  walletId: null;
  dpan: null;
  trid: null;
  callerReference: string;
  complianceCurrencyAmount: string;
  portfolioType: string;
  cardNumber: null | string;
  panLast4: null | string;
  cardNumberHash: null | string;
  mti: null | string;
  acquirerCurrency: null | string;
  acquirerAmount: null | string;
  settlementCurrency: null;
  settlementAmount: null;
  cardAcceptorName: null | string;
  cardAcceptorCity: null | string;
  cardAcceptorCountryCode: null | string;
  mcc: null | string;
  cardAcceptorId: null | string;
  processingCode: null | string;
  settlementCycle: null;
  authCode: null | string;
  createdAt: string;
  updatedAt: string;
  reversalTime: null;
  visaRebate: null;
  visaFee: null | string;
  swapCost: null | string;
  transactionSubType: number;
  acquiringInstitutionId: null | string;
  programAgentId: null;
  distributorAgentId: null;
  systemCost: null | string;
  agentRebate: null;
};

interface AnalysisVisaDailyTransactionReport {
  id: number;
  settlementDate: string;
  type: number;
  amount: string;
  charges: string;
  reimbursementFees: string;
  netSettlementAmount: string;
  createdAt: string;
  updatedAt: string;
  status: number;
}

const translateKeyObj = Object.freeze({
  settlementDate: "settlement_date",
  month: "month",
  merchantName: "merchant_name",
  transactionId: "transaction_id",
  programName: "program_name",
  customerNumber: "customer_number",
  panLast4: "pan_last4",
  acquirerCurrency: "acquirer_currency",
  acquirerAmount: "acquirer_amount",
  authorizationCode: "authorization_code",
  onlineTransaction: "online_transaction",
  merchantCategoryCodes: "merchant_category_codes",
  cardAcceptorId: "card_acceptor_id",
  cardAcceptorName: "card_acceptor_name",
  cardAcceptorCountry: "card_acceptor_country",
  cardAcceptorCity: "card_acceptor_city",
});

const omitKeyObj = Object.freeze({
  export: [],
  table: [],
});

export {
  translatePrefix,
  PermissionKey,
  type Ifield,
  type IsearchParam,
  type IgetAllRes,
  initFields,
  initZusParams,
  useZusParams,
  apiObj,
  translateKeyObj,
  omitKeyObj,
};
