import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { LoadingDialog } from '../../../components';
import { ExportBtn } from '../../../components/Button';
import {
  CustomPagination,
  NoRowsOverlay,
  SearchFirstDataGridOverlay,
  TableTab
} from '../../../components/Layout';
import { Container } from '../../../components/MuiGenerals';
import {
  useAlerting,
  useGetMerchant,
  useListMappingTransform,
  usePermission,
  useTranslation
} from '../../../hooks';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import {
  displayAmountCurrying,
  downloadFiles,
  getFullApiResponse,
  toDisplayTime
} from '../../../utils/helper';
import { customSx } from '../../../utils/styling';
import {
  apiObj as api,
  IgetAllRes,
  omitKeyObj,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

function ViewTable({ isSearched }: { isSearched: boolean }) {
  const { alerting } = useAlerting();
  const { tc, t } = useTranslation(translatePrefix);
  const zusParams = useZusParams();
  const [count, setCount] = useState(0);

  const listMapping = useListMapping("table");
  const { hasPermission } = usePermission();

  const onExport = async () => {
    if (count === 0) {
      return alerting("error", tc("no_data_export"));
    }
    const apiFn = (page: number, pageSize: number, signal: any) =>
      api.export({ ...zusParams.body, page, pageSize }, { signal });
    const rawRes = await getFullApiResponse(apiFn, count, true);
    if (rawRes.length === 0) return;
    const omitKeys = omitKeyObj.export;

    const res = listMapping(rawRes, omitKeys);

    const config = {};

    downloadFiles(`Transaction Post Monitoring Report`, res, config);
  };

  return (
    <Container
      style={customSx.datagridContainer}
      maxWidth={false}
      disableGutters
    >
      <ExportBtn
        isShown={hasPermission(PermissionKey.Export)}
        onExport={onExport}
      />
      <TableTab>
        <TableList setCount={setCount} isSearched={isSearched} />
      </TableTab>
    </Container>
  );
}

function TableList(props: {
  setCount: Dispatch<SetStateAction<number>>;
  isSearched: boolean;
}) {
  const { t } = useTranslation(translatePrefix);
  const { setCount, isSearched } = props;

  const zusParams = useZusParams();

  const listMapping = useListMapping("table");
  const listRes = useQuery({
    queryKey: [translatePrefix, zusParams.body, zusParams.refetchCounter],
    queryFn: () => {
      if (!isSearched) {
        return;
      }
      return api.table(zusParams.body);
    },
  });

  const { rows = [], count = 0 } =
    (listRes.data as any as { rows: any[]; count: number }) || {};

  const omitKeys = omitKeyObj.table;

  const content: GridRowsProp = listMapping(rows, omitKeys);

  useEffect(() => setCount(count), [listRes]);

  const columns: GridColDef[] = [
    useGenGridCol(TK.settlementDate, {
      minWidth: 150,
    }),
    useGenGridCol(TK.merchantName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.transactionId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.programName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.customerNumber, {
      minWidth: 150,
    }),
    useGenGridCol(TK.panLast4, {
      minWidth: 150,
    }),
    useGenGridCol(TK.acquirerCurrency, {
      minWidth: 150,
    }),
    useGenGridCol(TK.acquirerAmount, {
      minWidth: 150,
    }),
    useGenGridCol(TK.authorizationCode, {
      minWidth: 150,
    }),
    useGenGridCol(TK.onlineTransaction, {
      minWidth: 150,
    }),
    useGenGridCol(TK.merchantCategoryCodes, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardAcceptorId, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardAcceptorName, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardAcceptorCountry, {
      minWidth: 150,
    }),
    useGenGridCol(TK.cardAcceptorCity, {
      minWidth: 150,
    }),
  ];

  if (listRes.isLoading) return <LoadingDialog forceOpen={true} />;

  return (
    <>
      <DataGrid
        {...dataGridDefaults}
        rows={content}
        rowCount={count}
        columns={columns}
        page={zusParams.body.page}
        onPageChange={zusParams.setPage}
        components={{
          NoRowsOverlay: isSearched
            ? NoRowsOverlay
            : SearchFirstDataGridOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: count },
        }}
      />
    </>
  );
}

const useListMapping = (mode: "export" | "table") => {
  const { t, tb, tcc } = useTranslation("enumConstants");
  const listMappingTransform = useListMappingTransform(mode);

  const merchantObj = useGetMerchant();

  const toDisplayAmount = displayAmountCurrying(0, 2);

  const listMapping = (array: any[], omitKeys: string[] = []) => {
    const res = array.map((item: IgetAllRes) => {
      const mappedResult = [
        [TK.settlementDate, toDisplayTime(item?.settlementDate, "yyyy-MM-dd")],
        [TK.merchantName, item?.merchantId && merchantObj[item.merchantId]],
        [TK.transactionId, item?.transactionId],
        [TK.programName, item?.programName],
        [TK.customerNumber, item?.customerNumber],
        [TK.panLast4, item?.panLast4],
        [TK.acquirerCurrency, item?.acquirerCurrency],
        [TK.acquirerAmount, toDisplayAmount(item?.acquirerAmount)],
        [TK.authorizationCode, item?.authCode],
        [TK.onlineTransaction, tb(item?.isOnline)],
        [TK.merchantCategoryCodes, item?.mcc],
        [TK.cardAcceptorId, item?.cardAcceptorId],
        [TK.cardAcceptorName, item?.cardAcceptorName],
        [TK.cardAcceptorCountry, item?.cardAcceptorCountryCode],
        // [TK.cardAcceptorCountry, tcc(item?.cardAcceptorCountryCode)],
        [TK.cardAcceptorCity, item?.cardAcceptorCity],
      ].filter(([key]) => !omitKeys.includes(key as string));
      return mappedResult;
    });

    const output = res.map(listMappingTransform);
    return output;
  };
  return listMapping;
};

export default ViewTable;
